import { ResponseResultType } from '../../../config/api';
import { addQueryParams, composeUrl } from '../utils';
import { protectedApiClient } from '../../../config/overrides/api';
import {
  PrivateMeetingSessionDetailsListResponseType,
  PrivateMeetingDetailsResponseType,
  EventPrivateDetailsResponseType,
  CurrentUserResponseType,
  MeetingDetailsListResponseType,
  EVENT,
  MEETING,
  ATTEND,
  LEAVE,
  TRACK,
  TrackingDataEventType,
  GOOGlE,
  ICS,
  GoogleCalendarResponseType,
  EXPORT,
  SpeakerListResponseType,
  EVENT_TOKEN,
  WebinarRegistrationSubscriptionParams,
  FilterReplays,
  PrivateMeetingSessionDetailsResponseType,
  PREREGISTER_TO_MEETING,
  REGISTER_TO_MEETING
} from '../index';
import { FILTER_REPLAYS, SINGLE_MEETING_PATH, TOKEN, TYPE } from '../../constants';

export function fetchEventPrivateDetails(...args: string[]): ResponseResultType<EventPrivateDetailsResponseType> {
  return protectedApiClient.GET(composeUrl(...args));
}

export function fetchMeetingPrivateDetails(...args: string[]): ResponseResultType<PrivateMeetingDetailsResponseType> {
  return protectedApiClient.GET(composeUrl(...args));
}

export function fetchEventPrivateMeetingList(...args: string[]): ResponseResultType<MeetingDetailsListResponseType> {
  return protectedApiClient.GET(composeUrl(...args));
}

export function fetchMeetingPrivateSessionList(...args: string[]): ResponseResultType<PrivateMeetingSessionDetailsListResponseType> {
  return protectedApiClient.GET(composeUrl(...args));
}

export function fetchCurrentUserInfo(...args: string[]): ResponseResultType<CurrentUserResponseType> {
  return protectedApiClient.GET(composeUrl(...args));
}

export async function attendMeeting(eventId: string, meetingId: string): Promise<void> {
  return protectedApiClient.POST(composeUrl(EVENT, eventId, MEETING, meetingId, ATTEND));
}
export async function leaveMeeting(eventId: string, meetingId: string): Promise<void> {
  return protectedApiClient.DELETE(composeUrl(EVENT, eventId, MEETING, meetingId, LEAVE));
}

export async function sendTrackingData(data: TrackingDataEventType): Promise<void> {
  return protectedApiClient.POST(composeUrl(TRACK), data);
}

export async function fetchOutlookCalendarFile(
  eventId: string,
  meetingId: string,
  eventToken: string,
  singleMeetingPath?: string
): Promise<Blob> {
  const url = `${composeUrl(EVENT, eventId, MEETING, meetingId, EXPORT)}`;
  const urlWithParams = addQueryParams(url, {
    [TYPE]: ICS,
    [TOKEN]: eventToken,
    ...( singleMeetingPath && { [SINGLE_MEETING_PATH]: singleMeetingPath })
  });
  return await protectedApiClient.GET(urlWithParams);
}

export async function fetchGoogleCalendarLink(
  eventId: string,
  meetingId: string,
  eventToken: string,
  singleMeetingPath?: string
): Promise<GoogleCalendarResponseType> {
  const url = `${composeUrl(EVENT, eventId, MEETING, meetingId, EXPORT)}`;
  const urlWithParams = addQueryParams(url, {
    [TYPE]: GOOGlE,
    [TOKEN]: eventToken,
    ...( singleMeetingPath && { [SINGLE_MEETING_PATH]: singleMeetingPath })
  });
  return await protectedApiClient.GET(urlWithParams);
}

export async function fetchSpeakerList(...args: string[]): Promise<SpeakerListResponseType> {
  return await protectedApiClient.GET(composeUrl(...args));
}

export async function fetchReplaysList(...args: string[]): Promise<Array<PrivateMeetingSessionDetailsResponseType>> {
  const queryParam = args.pop() as FilterReplays;
  const urlWithParams = addQueryParams(composeUrl(...args), {
    [FILTER_REPLAYS]: queryParam
  });
  return await protectedApiClient.GET(urlWithParams);
}

export async function fetchEventToken(eventId: string): Promise<{ token: string }> {
  return await protectedApiClient.GET(composeUrl(EVENT, eventId, EVENT_TOKEN));
}

export async function fetchWebinarRegistrationSubscriptionParams(eventId: string, meetingId: string, connectionId?: number, joinMethodId?: number): Promise<WebinarRegistrationSubscriptionParams> {
  const url = composeUrl(EVENT, eventId, MEETING, meetingId, PREREGISTER_TO_MEETING);
  const urlWithParams = addQueryParams(url, {
    ...(connectionId && { connectionId: String(connectionId) }),
    ...(joinMethodId && { joinMethodId: String(joinMethodId) })
  });

  return await protectedApiClient.GET(urlWithParams);
}

export async function registerToWebinar(eventId: string, meetingId: string, registrationId: string, connectionId?: number, joinMethodId?: number): Promise<WebinarRegistrationSubscriptionParams> {
  const url = composeUrl(EVENT, eventId, MEETING, meetingId, REGISTER_TO_MEETING);
  const urlWithParams = addQueryParams(url, {
    ...(connectionId && { connectionId: String(connectionId) }),
    ...(joinMethodId && { joinMethodId: String(joinMethodId) })
  });

  return await protectedApiClient.POST(urlWithParams, { registrationId });
}
